var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1 col-12 pa-0 pa-sm-3",attrs:{"headers":_vm.headers,"items":_vm.bookings,"single-expand":_vm.singleExpand,"expanded":_vm.expanded,"item-key":"id","show-expand":"","loading":_vm.loading,"custom-sort":_vm.customSort},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return undefined},proxy:true},{key:"item.icon",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":_vm.statusAttrs(item.status, item).color}},[_vm._v(" "+_vm._s(_vm.statusAttrs(item.status, item)["icon"])+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"overline"},[_vm._v(" "+_vm._s(_vm.statusAttrs(item.status, item).status_admin)+" ")])]}},{key:"item.guestname",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.user.lastname + " " + item.user.firstname)+" ")]}},{key:"item.lastmodified",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.transitions[item.transitions.length - 1] ? _vm.formatDateTime( item.transitions[item.transitions.length - 1].created ) : "")+" ")]}},{key:"item.arrival",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.arrival))+" ")]}},(_vm.$vuetify.breakpoint.smAndUp)?{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.status == 'TENTATIVE' || item.status == 'BOOKED')?_c('v-btn',_vm._g(_vm._b({staticClass:"mt-auto align-self-end mr-2",attrs:{"disabled":_vm.loading,"fab":"","x-small":"","color":"transparent"},on:{"click":function($event){return _vm.mouseClickAccept(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","color":"success"}},[_vm._v(" "+_vm._s(_vm.statusAttrs(item.status, item).action_admin_icon_ok)+" ")])],1):_vm._e()]}}],null,true)},[_c('span',{staticClass:"text-caption"},[_vm._v(_vm._s(_vm.statusAttrs(item.status, item).action_admin_ok))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.status == 'TENTATIVE' || item.status == 'BOOKED')?_c('v-btn',_vm._g(_vm._b({staticClass:"mt-auto align-self-end mr-2",attrs:{"disabled":_vm.loading,"fab":"","x-small":"","color":"transparent"},on:{"click":function($event){return _vm.mouseClickCancel(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","color":"corange"}},[_vm._v(" "+_vm._s(_vm.statusAttrs(item.status, item).action_admin_icon_cancel)+" ")])],1):_vm._e()]}}],null,true)},[_c('span',{staticClass:"text-caption"},[_vm._v(_vm._s(_vm.statusAttrs(item.status, item).action_admin_cancel))])])]}}:null,{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.initialize}},[_vm._v(" Frissítés ")])]},proxy:true},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('div',{staticClass:"d-flex flex-wrap"},[_c('booking-tabs',{staticClass:"py-4 col-12 col-sm-10 pa-0 pa-sm-3",attrs:{"booking":item,"admin":true}}),_c('div',{staticClass:"col-12 col-sm-2"},[(item.status == 'TENTATIVE' || item.status == 'BOOKED')?_c('v-btn',{staticClass:"d-flex justify-start caption",attrs:{"disabled":_vm.loading,"large":"","text":""},on:{"click":function($event){return _vm.mouseClickAccept(item)}}},[_c('v-icon',{attrs:{"small":"","left":"","color":"success"}},[_vm._v(" "+_vm._s(_vm.statusAttrs(item.status, item).action_admin_icon_ok)+" ")]),_vm._v(" "+_vm._s(_vm.statusAttrs(item.status, item).action_admin_ok)+" ")],1):_vm._e(),(item.status == 'TENTATIVE' || item.status == 'BOOKED')?_c('v-btn',{staticClass:"d-flex justify-start caption",attrs:{"disabled":_vm.loading,"large":"","text":""},on:{"click":function($event){return _vm.mouseClickCancel(item)}}},[_c('v-icon',{attrs:{"small":"","left":"","color":"corange"}},[_vm._v(" "+_vm._s(_vm.statusAttrs(item.status, item).action_admin_icon_cancel)+" ")]),_vm._v(" "+_vm._s(_vm.statusAttrs(item.status, item).action_admin_cancel)+" ")],1):_vm._e(),_c('v-btn',{staticClass:"d-flex justify-start caption",attrs:{"disabled":_vm.loading,"large":"","text":""},on:{"click":function($event){return _vm.mouseClickUpdate(item)}}},[_c('v-icon',{attrs:{"small":"","left":"","color":"primary"}},[_vm._v(" fa-pencil ")]),_vm._v(" Állapot módosítás ")],1),(!_vm.active)?_c('v-btn',{staticClass:"d-flex justify-start caption",attrs:{"disabled":_vm.loading,"large":"","text":""},on:{"click":function($event){return _vm.mouseClickDelete(item)}}},[_c('v-icon',{attrs:{"small":"","left":"","color":"error"}},[_vm._v(" fa-trash ")]),_vm._v(" Végleges törlés ")],1):_vm._e()],1)],1)])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }