var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"d-flex justify-center pa-0 pa-sm-3 pb-sm-10"},[_c('v-data-table',{staticClass:"elevation-1 col-12",attrs:{"headers":_vm.headers,"items":_vm.users,"loading":_vm.loading},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Felhasználók")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer')],1)]},proxy:true},{key:"item.admin",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.admin)?_c('v-icon',_vm._g(_vm._b({attrs:{"color":"orange"}},'v-icon',attrs,false),on),[_vm._v(" mdi-crown ")]):_c('v-icon',_vm._g(_vm._b({attrs:{"color":"blue"}},'v-icon',attrs,false),on),[_vm._v(" mdi-account ")])]}}],null,true)},[(item.admin)?_c('span',[_vm._v("Admin jogosultságú felhasználó")]):_c('span',[_vm._v("Átlagos felhasználó")])])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.confirmDeleteDialog(item)}}},[_vm._v(" mdi-delete ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.confirmRoleChange(item)}}},[_vm._v(" mdi-crown ")])]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.initialize}},[_vm._v(" Reset ")])]},proxy:true}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }